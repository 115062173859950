import React, { useState, useRef } from 'react'
import { useStaticQuery, graphql, Link } from 'gatsby'
import styled, { css } from 'styled-components'
import tw from 'tailwind.macro'
import ProgressiveImage from 'react-progressive-image'
import IframeResizer from 'iframe-resizer-react'

import { parseACF } from '../utils'
import { Layout, PageHeader } from '../components'
import { media } from '../styles/utils'
import { container, padding } from '../styles/global'

const Stockists = props => {
    const data = parseACF(useStaticQuery(query), 'allWordpressInfopages')
    const iframeRef = useRef(null)
    console.log(data)

    return (
        <>
        <Layout
            meta={data && data.seo}
        >
            <Wrapper>
                <Container>
                    <PageHeader title={data?.intro_title || 'Stockists'} text={data?.intro_text} />
                    <IframeResizer
                        forwardRef={iframeRef}
                        // heightCalculationMethod="lowestElement"
                        // inPageLinks
                        log
                        // onMessage={onMessage}
                        // onResized={onResized}
                        src="https://sunstarflooring-stockists.netlify.app"
                        style={{ width: '1px', minWidth: '100%'}}
                    />
                </Container>
            </Wrapper>
        </Layout>
        </>
    )
}

// Shared

const Image = styled(ProgressiveImage)`
    overflow: hidden;
`

const LoadedImage = styled.img`
    transition: opacity 0.45s ease;
`

// Layout

const Wrapper = styled.div`
    ${tw`w-full`};
    padding-bottom: 9rem;
`

const Container = styled.div`
    ${container};
    ${padding};
`


export const query = graphql`
    query {
        allWordpressInfopages(filter: { slug: { eq: "stockists" } }) {
            nodes {
                acf_json
            }
        }
    }
`

export default Stockists
